<i18n src="@/i18n/client/auth/password_recovery.json"/>
<template>
	<section class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
		<div class="p-4 sm:p-8 max-w-md w-full bg-gray-50 border border-gray-700">
			<!-- header !-->
			<header>
				<!-- Logo !-->
				<div class="w-52 mx-auto mt-4">
					<img :src="$domain.logo" alt="Logo">
				</div>
				<!-- title !-->
				<h2 class="mt-6 text-center text-xl leading-6 font-extrabold text-gray-900">
					{{ $t('password_recovery.recovery_access') }}
				</h2>
				<p class="mt-2 text-center text-sm leading-5 text-gray-600">
					{{ $t('ou') }}
					<router-link to="/" class="font-medium text-turquoise hover:opacity-75 focus:outline-none focus:underline transition ease-in-out duration-150">
						{{ $t('password_recovery.access_with_your_credentials') }}
					</router-link>
				</p>
			</header>
			<!-- /header !-->
			<!-- content !-->
			<form class="mt-8" action="/register" method="POST">
				<!-- Credentials !-->
				<div class="rounded-md">
					<label>
                        <span class="mb-1 block text-sm font-medium leading-5 text-gray-700">
                          {{ $t('password_recovery.email') }}
                        </span>
						<FormInput v-model="fields.email" :validation="v$fields.email"/>
					</label>
				</div>
				<!-- / Credentials !-->
				<!-- Actions !-->
				<div class="mt-6">
					<button class="w-full py-3 text-white font-medium bg-turquoise rounded shadow hover:opacity-75" @click.prevent="handleSend">
						{{ $t('password_recovery.recover') }}
					</button>
				</div>
			</form>
			<!-- /content !-->
		</div>

		<Loading v-if="isLoading" overlay-opacity="75"/>
	</section>
</template>

<script>
import PageMixin from "./mixins/password_recovery"

import Loading from "@/components/Loading"
import FormInput from "@/components/FormInput"
import DefaultButton from "@/components/DefaultButton"

export default {
	name: "PasswordRecoveryPage",
	mixins: [PageMixin],
	components: {
		DefaultButton, Loading, FormInput
	},
	methods: {
		handleSend() {
			this.send()
		}
	}
}
</script>
