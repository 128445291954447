import "../bootstrap"
import Vue from "vue";
import App from "@/Auth.vue"
import store from "@/store"
import i18n from "@/i18n"
import router from "@/routes/client/routes_auth"

import localePlugin from "@/plugins/locale"
import domainPlugin from "@/plugins/domain"
import userPlugin from "@/plugins/user"
import tailwindPlugin from "@/plugins/tailwind.js"
import VueTheMask from 'vue-the-mask'
import "@/helpers"

Vue.use(VueTheMask)

import VCalendar from "v-calendar"

Vue.use(localePlugin)
Vue.use(domainPlugin)
Vue.use(tailwindPlugin)
Vue.use(userPlugin)
Vue.use(VCalendar)

Vue.config.productionTip = false

window.API_PREFIX = ""

new Vue({
    render: h => h(App),
    router,
    store,
    i18n
}).$mount("#App")
