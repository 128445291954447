import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

import LoginPage from "@/pages/client/auth/LoginPage"
import RegisterPage from "@/pages/client/auth/RegisterPage"
import PasswordRecoveryPage from "@/pages/client/auth/PasswordRecoveryPage"
import PasswordRenewPage from "@/pages/client/auth/PasswordRenewPage"

const routes = [
    {
        path: '/',
        component: LoginPage
    },
    {
        path: '/login',
        component: LoginPage
    },
    {
        path: '/register',
        component: RegisterPage,
        name: "register",
        props: true
    },
    {
        path: '/password/reset',
        component: PasswordRecoveryPage,
        props: true
    },
    {
        path: '/password/renew',
        component: PasswordRenewPage,
        props: true
    },
    {
        path: '*',
        component: LoginPage
    }
]

export default new VueRouter({
    mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})
