<i18n src="@/i18n/error.json"/>
<i18n src="@/i18n/client/auth/login.json"/>
<template>
	<section class="min-h-screen flex items-center justify-center py-6 sm:py-12 px-2 sm:px-6 lg:px-8">
		<div class="p-4 sm:p-8 max-w-md w-full bg-gray-50 rounded-sm shadow">
			<!-- header !-->
			<header>
				<!-- Logo !-->
				<div class="w-52 mx-auto mt-4">
					<img :src="$domain.logo" alt="Logo">
				</div>
				<!-- title !-->
				<h2 class="mt-6 text-center leading-9 text-gray-700 text-sm">
					<span>{{ $t('login.entrar_em_sua_conta') }}</span>
					{{ $t('login.ou_se') }}
					<button class="text-turquoise opacity-75 hover:opacity-100 focus:outline-none focus:underline" @click="handleRegister">{{ $t('login.cadastre') }}</button>
				</h2>
			</header>
			<!-- /header !-->

			<!-- Social Oauth !-->
			<div class="flex flex-col sm:flex-row my-6">
				<button
					class="w-full py-2 text-lg sm:text-base rounded border border-gray-200 bg-white hover:bg-turquoise hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-turquoise"
					@click="handleLoginWithSocialAccount('facebook')">
					<i class="fab fa-facebook-f"></i>
				</button>
				<button
					class="w-full my-4 text-lg sm:text-base sm:my-0 sm:mx-4 py-2 rounded border border-gray-200 bg-white hover:bg-turquoise hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-turquoise"
					@click="handleLoginWithSocialAccount('google')">
					<i class="fab fa-google"></i>
				</button>
				<button
					class="w-full py-2 text-lg sm:text-base rounded border border-gray-200 bg-white hover:bg-turquoise hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-turquoise"
					@click="handleLoginWithSocialAccount('microsoft')">
					<i class="fab fa-microsoft"></i>
				</button>
			</div>
			<!-- /Social Oauth !-->

			<div class="relative">
				<div class="absolute inset-0 flex items-center px-16">
					<div class="w-full border-t border-gray-400"></div>
				</div>
				<div class="relative flex justify-center text-sm leading-5">
            <span class="px-2 text-gray-500 bg-gray-50">
              {{ $t('ou') }}
            </span>
				</div>
			</div>

			<!-- content !-->
			<form class="mt-8" action="#" method="POST" autocomplete="off">
				<input type="hidden" name="remember" value="true"/>
				<!-- Credentials !-->
				<label class="block">
					<span class="text-sm">{{ $t('login.username') }}</span>
					<FormInput ref="email" data-cy="email" v-model="fields.email" :validation="v$.fields.email" class="mt-1"/>
				</label>
				<label class="block mt-4">
					<span class="text-sm">{{ $t('login.password') }}</span>
					<FormInput ref="password" data-cy="password" v-model="fields.password" :validation="v$.fields.password" type="password" class="mt-1"/>
				</label>
				<!-- / Credentials !-->
				<!-- Options !-->
				<div class="mt-6 flex items-center justify-between">
					<div class="flex items-center">
						<input id="remember_me" type="checkbox" class="form-checkbox border-gray-300 shadow-sm h-4 w-4 text-turquoise rounded" v-model="fields.remember_me"/>
						<label for="remember_me" class="ml-2 block text-sm leading-5 select-none">
							{{ $t('login.manter_conectado') }}
						</label>
					</div>
					<div class="text-sm leading-5">
						<router-link to="/password/reset"
									 class="font-medium text-turquoise hover:opacity-75 focus:outline-none focus:underline transition ease-in-out duration-150">
							{{ $t('login.esqueceu_senha') }}
						</router-link>
					</div>
				</div>
				<!-- /Options !-->
				<div class="mt-6">
					<button
						class="w-full py-3 text-white font-medium bg-turquoise rounded shadow opacity-75 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-turquoise"
						@click.prevent="handleSend">
						{{ $t('login.entrar') }}
					</button>
				</div>
			</form>
			<!-- /content !-->
		</div>

		<Loading v-if="isLoading"/>
	</section>
</template>

<script>
import Mixin from "./mixins/login"

import Loading from "@/components/Loading"
import FormInput from "@/components/FormInput"

export default {
	name: "LoginPage",
	mixins: [Mixin],
	components: {
		Loading, FormInput
	},
	methods: {
		async handleSend() {
			if (await this.send()) {
				this.$alertSuccess(this.$t('login.redirecting'))
				setTimeout(() => window.location = '/', 200)
			}
		},
		handleRegister() {
			this.$router.push({name: "register"})
		},
		handleLoginWithSocialAccount(social) {
			this.loginWithSocialAccount(social)
		}
	},
	created() {
		this.init()
	}
}
</script>
